import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Body2, Headline4 } from 'components/Texts'
import { DateWrapper, DayWrapper, MonthWrapper } from './styledComponents'

const Date = ({ publishDate }) => {
  const date = moment(publishDate, 'DD.MM.YY h:mm')
  const monthDay = date.format('D')
  const monthName = _.toUpper(date.locale('de').format('MMM'))

  return (
    <DateWrapper>
      <DayWrapper>
        <Headline4 white bold>
          {monthDay}
        </Headline4>
      </DayWrapper>

      <MonthWrapper>
        <Body2 white>{monthName}</Body2>
      </MonthWrapper>
    </DateWrapper>
  )
}

export default Date
