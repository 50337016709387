import React from 'react'

import Translation from 'utils/Translation'
import { NoStylesLink } from 'components/Links'
import { Body1 } from 'components/Texts'
import { MoreButtonWrapper } from './styledComponents'

const MoreButton = ({ slug }) => (
  <NoStylesLink to={`/blog/${slug}`}>
    <MoreButtonWrapper>
      <Body1 white>
        <Translation id="readMore" />
      </Body1>
    </MoreButtonWrapper>
  </NoStylesLink>
)

export default MoreButton
