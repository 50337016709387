import styled from 'styled-components'
import { theme } from '../../styles/themes'

const buttonReset = `
  border: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  &:focus,
  &:active {
    outline: none;
  }
`

export const PaginationArrowLink = styled.button`
  display: flex;
  align-items: center;
  color: ${theme.Colors.mediumBlack};
  cursor: pointer;
  transition: 250ms ease-in-out;
  ${buttonReset};
  &:hover {
    color: ${theme.Colors.highBlack};
  }
`

export const PaginationArrowLinkIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-right: 16px;
  }
  &:last-child {
    margin-left: 16px;
  }
  svg {
    height: 1em;
    width: 1em;
    fill: currentColor;
    font-size: 20px;
  }
`

export const PaginationLink = styled.button`
  ${buttonReset};
  color: currentColor;
  padding: 3px 1px 1px;
  border-bottom: 2px solid transparent;
  transition: 250ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${theme.Colors.highBlack};
  }
`

export const PaginationWrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
  border-top: 1px solid ${theme.Colors.light};
  padding-top: 30px;
  .rc-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .rc-pagination-next,
  .rc-pagination-prev {
    width: calc((100% - 157px) / 2);
    display: flex;
    align-items: center;
  }

  .rc-pagination-next {
    justify-content: flex-end;
    padding-left: 16px;
  }

  .rc-pagination-prev {
    padding-right: 16px;
  }

  .rc-pagination-item {
    color: ${theme.Colors.mediumBlack};
  }

  .rc-pagination-item-active {
    color: ${theme.Colors.secondary};
    pointer-events: none;
    ${PaginationLink} {
      border-color: ${theme.Colors.secondary};
    }
  }

  .rc-pagination-disabled {
    ${PaginationArrowLink} {
      pointer-events: none;
      color: ${theme.Colors.disabledBlack};
    }
  }
`
