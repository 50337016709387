import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import _ from 'lodash'
import Helmet from 'react-helmet'

import Layout, { Inner } from 'components/Layout'
import ArticleItem from 'components/ArticleItem'
import Pagination from 'components/Pagination'
import Hero from 'components/Hero'

import { theme } from 'styles/themes'
import { usePathname } from 'hooks'
import { ALL_TAGS, COOKIE_PRO_ID } from 'consts'

const ArticlesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
`

const MainTemplate = ({ pageContext: { currentPage, itemCount }, data }) => {
  const [activeTag] = useState(_.first(usePathname()) || ALL_TAGS)

  const configSiteTitle = _.get(data, 'site.siteMetadata.title')
  const posts = _.get(data, 'allContentfulBlogPost.edges')
  const placeholderImage = _.get(data, 'file.childImageSharp.fluid')
  const { siteTitle, description, author, keywords } = _.get(
    data,
    'contentfulWebPageMetaTags',
    {},
  )

  return (
    <Layout>
      <Helmet>
        <script
          src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={COOKIE_PRO_ID}
        ></script>
        <script type="text/javascript">function OptanonWrapper() {}</script>
        <title>{siteTitle || configSiteTitle}</title>
        <meta name="description" content={description} />
        <meta name="author" content={author} />
        <meta name="keywords" content={keywords} />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <Hero />

      <Inner>
        <div className="wrapper">
          <ArticlesWrapper>
            {posts.map(({ node }) => (
              <ArticleItem
                key={node.slug}
                article={node}
                {...{ placeholderImage }}
              />
            ))}
          </ArticlesWrapper>
        </div>
        <Pagination {...{ currentPage, itemCount, activeTag }} />
      </Inner>
    </Layout>
  )
}

export default MainTemplate

export const mainQuery = graphql`
  query MainQuery($skip: Int!, $limit: Int!, $tag: String) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulWebPageMetaTags(page: { eq: "blog" }) {
      author
      description
      keywords
      siteTitle
    }

    file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { tags: { eq: $tag } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "DD.MM.YY HH:MM")
          tags
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
