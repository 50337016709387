import React from 'react'
import Img from 'gatsby-image'
import { Flex, Box } from '@rebass/grid'
import _ from 'lodash'

import { Headline2, Headline5, Body2 } from 'components/Texts'
import { MobileHideWrapper, Inner } from 'components/Layout'
import { useLayout } from 'components/Layout/context'
import { NoStylesLink } from 'components/Links'
import MoreButton from 'components/MoreButton'
import { Search as SearchIcon } from 'components/Icons'

import useTranslation from 'utils/Translation/useTranslation'
import Translation from 'utils/Translation'

import {
  Content,
  HeroWrapper,
  AuthorSmall,
  PinnedArticle,
  PinnedArticleDetails,
  PinnedArticleDetailsWrapper,
  TopTitleWrapper,
  Input,
  SearchIconWrapper,
  SearchInputInternalWrapper,
  SearchInputExternalWrapper,
} from './styledComponents'

const HeroComponent = data => {
  const {
    title,
    description: { description },
    heroImage,
    author,
    slug,
  } = _.get(data, 'allContentfulBlogPost.edges[0].node')
  const placeholderImage = _.get(data, 'file.childImageSharp.fluid')

  const { title: authorTitle, name: authorName, image: authorImage } =
    author || {}

  const { toggleSearchMenu } = useLayout()

  return (
    <HeroWrapper>
      <MobileHideWrapper>
        <TopTitleWrapper>
          <Inner>
            <Headline2 extraBold>
              <Translation id="news" />
            </Headline2>
          </Inner>
        </TopTitleWrapper>
      </MobileHideWrapper>
      <MobileHideWrapper>
        <SearchInputExternalWrapper>
          <SearchInputInternalWrapper onClick={toggleSearchMenu}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              type="text"
              readOnly
              placeholder={useTranslation('search')}
            />
          </SearchInputInternalWrapper>
        </SearchInputExternalWrapper>
      </MobileHideWrapper>

      <Inner>
        <Content>
          <PinnedArticle>
            <NoStylesLink
              to={`/blog/${slug}`}
              style={{ height: '100%', width: '100%' }}
            >
              <Img
                alt={`Image of article ${slug}`}
                fluid={_.get(heroImage, 'fluid', placeholderImage)}
                style={{ width: '100%', height: '100%' }}
              />
            </NoStylesLink>

            <PinnedArticleDetailsWrapper>
              <PinnedArticleDetails>
                <NoStylesLink to={`/blog/${slug}`}>
                  <Headline5 color="white" bold margin={0} as="h2">
                    {title}
                  </Headline5>
                </NoStylesLink>

                <Box pt="30px">
                  <Body2 themeColor="mediumWhite" mb="22px">
                    {description}
                  </Body2>

                  <MoreButton slug={slug} />
                </Box>
              </PinnedArticleDetails>

              <AuthorSmall>
                <Img
                  fluid={_.get(authorImage, 'fluid', placeholderImage)}
                  alt={`${authorName} - profile image`}
                  style={{
                    width: '44px',
                    height: '44px',
                    borderRadius: '22px',
                  }}
                />

                <Flex flexDirection="column" pl="12px">
                  <Body2 themeColor="highWhite" bold margin={0}>
                    {authorName}
                  </Body2>
                  <Body2 themeColor="mediumWhite" margin={0}>
                    {authorTitle}
                  </Body2>
                </Flex>
              </AuthorSmall>
            </PinnedArticleDetailsWrapper>
          </PinnedArticle>
        </Content>
      </Inner>
    </HeroWrapper>
  )
}

export default HeroComponent
