import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import { theme } from 'styles/themes'

export const DateWrapper = styled(Flex)`
  flex-direction: column;
  width: 68px;
  height: 74px;
  background-color: ${theme.Colors.secondary600};
  position: relative;
  top: 58px;
  z-index: 1;
  left: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 3px 3px 0 rgba(0, 0, 0, 0.14);
`
export const DayWrapper = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: center;
`
export const MonthWrapper = styled(Flex)`
  height: 22px;
  background-color: ${theme.Colors.tertiary};
  align-items: center;
  justify-content: center;
`
