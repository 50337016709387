import React from 'react'
import RCPagination from 'rc-pagination'
import { navigate } from 'gatsby'

import { ITEMS_PER_PAGE } from 'consts'
import { ChevronRight, ChevronLeft } from 'components/Icons'
import { ButtonText } from 'components/Texts'
import { MobileHideWrapper } from 'components/Layout'
import Translation from 'utils/Translation'

import {
  PaginationArrowLink,
  PaginationArrowLinkIcon,
  PaginationLink,
  PaginationWrapper,
} from './styledComponents'
import { ITEM_TYPES } from './consts'

const Pagination = ({ currentPage: current, itemCount: total, activeTag }) => {
  const onChange = page => navigate(`/${activeTag}/${page}`)

  const renderItem = (pageNumber, type) => {
    switch (type) {
      case ITEM_TYPES.PREV:
        return (
          <PaginationArrowLink>
            <PaginationArrowLinkIcon>
              <ChevronLeft />
            </PaginationArrowLinkIcon>
            <ButtonText>
              <MobileHideWrapper>
                <Translation id="previous" />
              </MobileHideWrapper>
            </ButtonText>
          </PaginationArrowLink>
        )
      case ITEM_TYPES.NEXT:
        return (
          <PaginationArrowLink>
            <ButtonText>
              <MobileHideWrapper>
                <Translation id="next" />
              </MobileHideWrapper>
            </ButtonText>
            <PaginationArrowLinkIcon>
              <ChevronRight />
            </PaginationArrowLinkIcon>
          </PaginationArrowLink>
        )
      case ITEM_TYPES.JUMP_NEXT:
      case ITEM_TYPES.JUMP_PREV:
        return <ButtonText>...</ButtonText>
      default:
        return (
          <PaginationLink>
            <ButtonText>{pageNumber}</ButtonText>
          </PaginationLink>
        )
    }
  }

  return (
    <PaginationWrapper>
      <RCPagination
        itemRender={renderItem}
        {...{ onChange, current, total, pageSize: ITEMS_PER_PAGE }}
      />
    </PaginationWrapper>
  )
}

export default Pagination
