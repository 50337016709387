import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import HeroComponent from './HeroComponent'

const Hero = () => (
  <StaticQuery
    query={graphql`
      query HeroQuery {
        file(relativePath: { eq: "placeholder.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        allContentfulBlogPost(
          sort: { fields: publishDate, order: DESC }
          limit: 1
        ) {
          edges {
            node {
              title
              tags
              slug
              description {
                description
              }
              publishDate
              heroImage {
                fluid {
                  ...GatsbyContentfulFluid_tracedSVG
                }
              }
              author {
                name
                title
                image {
                  fluid(maxHeight: 44, maxWidth: 44) {
                    ...GatsbyContentfulFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={HeroComponent}
  />
)

export default Hero
