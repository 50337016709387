import React from 'react'
import Img from 'gatsby-image'
import { Flex, Box } from '@rebass/grid'
import _ from 'lodash'

import { HTMLWrapper } from 'components/styledComponents'
import { Body2, Headline5 } from 'components/Texts'
import { NoStylesLink } from 'components/Links'
import MoreButton from 'components/MoreButton'
import Date from 'components/Date'

import { Wrapper, CoverImage } from './styledComponents'

const ArticleItem = ({
  article: {
    heroImage,
    slug,
    title,
    publishDate,
    description,
    tags: articleTags,
  } = {},
  placeholderImage,
}) => {
  const tags = articleTags || []

  return (
    <Wrapper>
      <NoStylesLink to={`/blog/${slug}`}>
        <CoverImage>
          <Date {...{ publishDate }}></Date>
          <Img
            alt={`${title} - image`}
            fluid={_.get(heroImage, 'fluid', placeholderImage)}
            style={{
              height: '310px',
              borderRadius: '3px',
            }}
          />
        </CoverImage>
      </NoStylesLink>

      <Flex flexDirection="column">
        <Body2 pt="30px" gray>
          {tags.join(' & ')}
        </Body2>

        <NoStylesLink to={`/blog/${slug}`}>
          <Headline5 pt="4px" bold as="h3">
            {title}
          </Headline5>
        </NoStylesLink>

        <Box pt="12px" pb="12px">
          <HTMLWrapper
            gray
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        </Box>
      </Flex>

      <MoreButton {...{ slug }}></MoreButton>
    </Wrapper>
  )
}

export default ArticleItem
