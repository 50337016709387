import styled from 'styled-components'
import { theme } from 'styles/themes'

export const Wrapper = styled.div`
  max-width: 510px;
  display: flex;
  flex-direction: column;
  margin: 33px;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    margin: 33px 0;
  }
`

export const CoverImage = styled.div`
  border-radius: 3px;
`
