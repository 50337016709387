import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const MoreButtonWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  height: 38px;
  border-radius: 2px;
  background-color: #317fa7;
`
