import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { body1Styles, bodyBaseStyles } from 'components/Texts'

import { theme } from 'styles/themes'

export const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 0;
  padding-bottom: 52px;
`

export const Content = styled(Flex)`
  flex-direction: column;
  z-index: 1;
  align-items: center;
  width: 100%;
  margin-top: 80px;
`

export const PinnedArticle = styled(Flex)`
  width: 100%;
  background-color: ${theme.Colors.gray2};
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 3px 3px 0 rgba(0, 0, 0, 0.14);
  height: 450px;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    flex-direction: column;
    height: 100%;
  }
`

export const PinnedArticleDetailsWrapper = styled(Flex)`
  flex-direction: column;
  flex: 1 0 354px;
  padding: 25px;
  min-width: 354px;
  justify-content: space-between;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    flex-direction: column;
    min-width: initial;
    flex-basis: auto;
  }
`

export const PinnedArticleDetails = styled(Flex)`
  flex-direction: column;
`

export const AuthorSmall = styled(Flex)`
  height: 44px;
  margin-top: 30px;
  @media (max-width: ${theme.Breakpoints.mobile}) {
    margin-top: 10px;
    height: auto;
  }
`

export const TopTitleWrapper = styled.div`
  width: 100%;
  background-color: ${theme.NewColors.light};
  padding: 64px 0%;
`

export const Input = styled.input`
  ${bodyBaseStyles};
  ${body1Styles};

  margin-left: 16px;
  border: none;
  width: 100%;
  background-color: transparent;
  cursor: pointer;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${theme.Colors.disabledBlack};
  }
`

export const SearchIconWrapper = styled.button`
  flex: 0;
  border: none;
  background: transparent;
  cursor: pointer;
`

export const SearchInputInternalWrapper = styled(Flex)`
  width: 100%;
  max-width: ${theme.Sizes.inner};
  padding: 24px 16px;
  cursor: pointer;
`
export const SearchInputExternalWrapper = styled(Flex)`
  width: 100%;
  justify-content: center;
  border-bottom: solid 1px ${theme.Colors.primary100};
  outline: none;

  margin: 0 auto;
`
